@media (max-width: 840px) {
  .pageWrapper {
    padding-bottom: 220px;
  }

  header {
    height: 100px;

    .content-box {
      .row {
        & > div {
          width: 100%;
        }
      }
    }

    .right {
      & > div {
        background: $colorWhite;
      }

      .profile {
        float: left;
        width: auto;
      }

      #profile {
        position: relative;
        z-index: 99;
        width: 38px;
        left: -9px;
        top: 1px;
        float: left;
      }
    }

    menu {
      margin-top: 45px;
      clear: both;

      ul {
        display: table;
        width: 100%;

        li {
          padding: 0 4px 0 3px !important;
          display: table-cell;
          /*width: 25%;*/
          text-align: center;

          a {
            padding: 0 0 5px;
          }

          &:last-of-type {
            ul {
              margin-left: -100px;

              &:before {
                right: 50px;
                left: auto;
              }
            }
          }

          ul {
            display: none;
            position: absolute;
            background: $colorWhite;

            &:last-of-type {
              right: 0;
            }

            li {
              display: list-item;
              padding: 0 15px;
              border-bottom: 1px solid #d6d8da;
              width: 100%;
              text-align: left;

              a {
                color: $colorPureBlack !important;
              }
            }
          }
        }
      }
    }

    .right {
      form {
        width: 100% !important;
        /*margin: -55px 0 0 0 !important;*/
        margin: 0 0 0 0 !important;
        display: block !important;

        &.hide {
          display: none;
        }

        &.show {
          display: none;
        }
      }
    }

  }

}

@media(max-width: 600px) {

  .popup {
    width: 90% !important;
    max-height: 80vh;
    margin: 0 !important;
    position: fixed;
    top: 100px;
    left: 5%;

    .content {
      input {
        width: 90%;
        margin: 0 5% 20px;
      }
    }

    .buttons {
      p {
        width: 100%;
        margin: 0 0 10px 10px;
      }

      a,
      a.a.buyProButton {
        float: none !important;
      }
    }
  }
  .rejectPopup,
  .deletePopup {
    width: 350px !important;
  }
  .login .buttons a {
    margin: 0px 0px 0 10px;
    //float: left !important;
  }

  .register {
    .content {
      .short,
      .Select {
        width: 90% !important;
      }
    }
  }

  .newsContent {
    .lock {
      a {
        max-width: 90%;
        width: 90%;
      }
    }
  }

  .widget {
    display: none;
  }

  .content-block .right-side .newsContent .newsSingle .img {
    width: 100%;
    height: 280px;
  }

  .content-block .right-side .newsContent .newsSingle {
    margin-bottom: 20px;
  }

  section.directory .rightside .filters .firstLine a.addStartup {
    width: 22px;
    background: transparent;
    padding: 0;
    height: 25px;
    &:before {
      background: url($PUBLIC_IMG_URL+"/mobile-plus-icon.png") center center no-repeat;
      width: 21px;
      height: 21px;
      margin: 3px 0 0 0 !important;
    }
  }
  section.directory .rightside .filters .mobilefilter-main-wrap {
    padding: 0 15px;
    background: $colorWhite;
    display: table;
    width: 100%;
    border: 1px solid $colorBorder;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;

    div {
      display: block;
      background: none !important;

      &:last-of-type {
        width: 100%;
        border-right: 0;
        padding-left: 0px;
      }
    }

    .mobile-secondLine-filter-label {
      padding: 6px 0 6px 0px;
      margin: 0;
      margin-left: 0;
      font-size: 16px;
      line-height: 45px;
      display: inline-block;
      background-position: left center;
      background-repeat: no-repeat;
      display: block;

      a {
        background: url($PUBLIC_IMG_URL+"/icons/mobile-filters-icon.png") 95% center no-repeat;
        float: right;
        display: inline-block;
        width: 22px;
        height: 17px;
        margin-top: 12px;
      }
    }
    .mobile-secondLine-filters {
      padding-top: 15px;
      width: 100% !important;
      padding-left: 0 !important;
      display: block;

      .secondLine-options {
        display: block;
        text-align: center;
        border-right: 0px solid $colorBorder;
        width: 100% !important;
        margin-bottom: 7px;
        position: relative;

        &:last-of-type {
          width: auto !important;
          border-right: 0;
          padding-left: 0px;
        }
        a.addFilter {
          display: block;
          border-radius: 4px;
          margin: 10px 0 10px 0;
          padding: 10px 13px;
          width: 100%;
          background-color: $colorRed;
          font-size: 12px;
        }
        input {
          background: url($PUBLIC_IMG_URL+"/icons/search.png") 95% center no-repeat #f8f8f8;
          background-size: 15px;
          padding: 0 5%;
          height: 50px;
          line-height: 50px;
          color: $colorBlack1;
          border: 0;
          width: 100%;
          font-size: 12px;
          margin: 0 auto;
          margin-bottom: 2px;
        }
        select {
          width: 100%;
          margin: 0;
          padding: 0 14%;
          font-size: 12px;
          color: $colorBlack1;
          border-radius: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none;
          text-indent: 0.01px;
          appearance: none !important;
          background: url($PUBLIC_IMG_URL+"/icons/dropdown.png") 95% center no-repeat #f8f8f8;
          background-size: 12px;
        }
      }
    }
  }

  section.directory .rightside .table .row {
    display: table-row;
    width: 100%;
    padding: 0 10px;
    margin-left: -9px;
    margin-right: -9px;
    //height: 55px;
  }
  section.directory .rightside .table .row .element:first-of-type {
    max-width: 24px;
    width: 24px;
    min-width: 24px;
    padding: 0;
  }
  section.directory .rightside .table .row .element {
    display: table-cell;
    vertical-align: middle;
    padding: 10px 9px;
    max-width: 97px;
    min-width: 80px;
    border-bottom: 1px solid $colorBorder;
  }

  section.directory .rightside .table .row .element:nth-child(2) h3 {
    white-space: initial;
    max-width: 60px;
    word-break: break-all;
  }
  .lock {
    padding: 50px 15px 55px 15px;
  }

  section.directory
    .rightside
    .mobilefilter-main-wrap
    .mobile-secondLine-filters
    .secondLine-options
    input {
    background: url($PUBLIC_IMG_URL+"/icons/search.png") 4% center no-repeat #f8f8f8;
    background-size: 15px;
    padding: 0 12%;
  }

  .applicationRight {
    float: right;
    width: 72%;
    text-align: left;
    .appLink {
      width: 100%;

      h2 {
        width: 100% !important;
      }
    }
    .appDesc {
      width: 100% !important;
    }
    .info {
      width: 100% !important;

      div {
        line-height: 19px !important;
        margin-bottom: 11px !important;
      }
      span {
        font-size: 12px !important;
      }
    }
    .costs {
      width: 100% !important;
      float: left !important;
      margin: 10px 0 10px 0px !important;
      text-align: left !important;
    }
  }

  .content-block {
    .right-side {

      .jobsContent {
        .seeall {
          margin-top: 20px;
          background: none;
          color: $colorRed;
          margin-bottom: 0;
          line-height: 10px;
        }
      }
    }
  }
  .spinner {
    padding: 0;
  }
  .pad5 {
    padding: 0 5% !important;
  }

  .padbot35 {
    padding-bottom: 35px;
  }
  section.directory .rightside .table .row:first-of-type i {
    display: inline-block;
    position: relative;
    width: 13px;
    height: 13px;
  }
  section.directory .rightside .table .row:first-of-type i:before {
    font-size: 13px;
  }
  .content-block .right-side .jobsContent .jobSingle .costs a {
    border: 1px solid $colorRed;
    color: $colorRed;
    font-size: 12px;
    padding: 5px 35px;
    border-radius: 4px;
    text-transform: uppercase;
    width: 100%;
    display: block;
    text-align: center;
  }
  .addTtitle {
    .progressLine {
      margin: 68px auto 0;
      height: 543px;
      background: #e5e6e8;
      z-index: 5;
      position: absolute;
      left: 50%;
      width: 1%;
    }
    .progressBlock {
      margin-top: 30px;
      width: 100%;

      .progressRound {
        width: 100%;

        p {
          margin: 15px 0;
          padding: 5px;
          background: $colorWhite;
        }
      }
    }
  }
  .addBlock {
    .mainContent {
      .left,
      .right {
        width: 100%;
      }
    }
  }
  .addBlock .mainContent .right .row > div.col-xs-6,
  .addBlock .mainContent .right .row > div.col-sm-6,
  .addBlock .mainContent .right .row > div.col-md-6 {
    width: 100%;
  }
  .addBlock .mainContent .create {
    margin: 0px 0 14px 0;
  }
  .addBlock .mainContent .right p.or {
    font-size: 14px;
    top: 0;
    width: 100%;
    position: unset;
    z-index: 1;
    text-align: center;
    margin-bottom: 16px;
    clear: both;
  }


  .addBlock .mainContent .right .row > div.col-xs-3,
  .addBlock .mainContent .right .row > div.col-sm-3,
  .addBlock .mainContent .right .row > div.col-md-3 {
    width: 100%;
  }
  .addBlock .mainContent .right .addTeam {
    margin: 10px 0 10px 0;
  }
  .addBlock .mainContent .right .row,
  .addBlock .mainContent .right .row div {
    z-index: inherit;
  }
  .addBlock .mainContent .right .addAdditional .row > div {
    margin: 5px 0 !important;
  }
  .startup-progressLine {
    height: 425px !important;
  }
  .errorMessage2 {
    padding: 0px 0 0 0;
  }
  .padtop0 {
    padding-top: 0 !important;
  }
  .addBlock .mainContent .right ul.checkboxes li {
    width: 100%;
  }

  .custom-msg {
    .successMessage {
      padding: 0px 0 0 0 !important;
    }
  }
  .twosteps-height .progressLine {
    height: 187px !important;
  }
  .martop15 {
    margin-top: 15px !important;
  }
  .investorPage.directory .rightside .table .row .element {
    max-width: 115px;
  }
  .investorPage.directory .rightside .table .row .element:nth-child(2) .logo {
    width: 40px;
    height: 40px;
    margin: 8px 5px 0 0;
  }
  .top-block {
    .logoBlock {

      h2 {
        line-height: 92px;
      }
    }
    .textInformation {

      h1 {
        font-weight: bold;
        margin-bottom: 0;
      }
      p {
        word-break: break-all;
      }
    }
    .mobile-info-wrap {
      width: 100% !important;
      padding-bottom: 15px;
      font-size: 11px;

      .info {
        margin-left: 0;
      }
    }
    .buttons {
      float: none;
      clear: both;
      margin: 0 auto;
      width: 100%;
      display: block;
      text-align: center;
    }
    .description {
      iframe {
        max-width: 100%;
      }
      .productPicture {
        max-width: 100%;
      }
    }
    .mobile-profile-buttons {
      a {
        padding: 5px 25px 7px 11px;
        margin: 5px 10px 5px 0 !important;
        display: inline-block;
        width: auto;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .mobile-profile-buttons a.apply {
    height: auto;
    width: auto;
    min-height: initial;
  }

  .pad10 {
    padding: 0 10px !important;
  }
  .dblock {
    display: block !important;

    .widget {
      display: block !important;
    }
  }

  .jobSingle {
    text-align: center;
    .jobSingleImage {
      margin: auto;
      float: none;

      a {
        border: 1px solid #d2d2d2;
        display: inline-block;
        padding: 4px;
        width: 90px;
        height: 90px;

        .img {
          float: none;
          margin: 0;
        }
      }
    }
    .jobTitle {
      margin-top: 8px;
    }
    .jobTitleText {
      h1 {
        width: 100% !important;
        text-align: center !important;
        border-bottom: 1px solid $colorBorder;
        padding-bottom: 12px;
        margin-bottom: 19px !important;
        float: none;
      }
    }
    .costs {
      width: 100% !important;
      float: none !important;
      text-align: center !important;
    }
    .jobCost {
      text-align: right !important;
    }
    .jobInfowidth {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }
  .content-block .right-side .jobsContent .mainContent .left {
    width: 100%;
    float: left;
  }
  .content-block .right-side .jobsContent .mainContent .right {
    width: 100%;
    float: left;
    margin-top: 15px;
  }
  .content-block .right-side .jobsContent .mainContent .right p {
    font-size: 14px;
    margin-bottom: 15px;
    overflow: hidden;
    font-weight: 300;
  }
  .marbot0 p {
    margin-bottom: 0 !important;
  }
  .mobileServiceLogo img {
    width: 90px !important;
    height: 90px !important;
  }
  .top-block .description .showmore {
    display: block;
    text-align: center;
    margin-top: 15px;
  }
  .content-block .right-side .jobsContent .mainContent .right .links {
    margin-bottom: 25px;
    float: left;
    width: 100%;
  }
  .content-block .right-side .jobsContent .mainContent .right .links a {
    margin-bottom: 3px;
    color: $colorBlack;
    margin-right: 10px;
    float: left;
    width: 46%;
  }

  .mobDasboardBlock {
    .title {
      margin: 0 auto;
      padding: 5px 0;
      border-bottom: 1px solid $colorBorder;
      float: left;
      width: 100%;

      h3 {
        float: left;
        //width: 59%;
        width: 100%;
        display: block;
        margin-right: 2%;
      }
    }
  }
  .overHidden {
    overflow: hidden;
  }
  .jobDashBoardBlock .previewInfo div {
    width: 30%;
    background: #f8f8f8;
    float: left;
    margin: 3% 2.48% 0;
    height: 51px;
    text-align: center;
    padding: 1px;
  }
  .jobDashBoardBlock .previewInfo div h3 {
    font-size: 15px;
  }
  .jobDashBoardBlock .previewInfo div p {
    font-size: 15px;
  }
  .rightSidemobDasboardBlock {
    float: left;
    //width:39%;
    width: 100%;
    text-align: right;
    a {
      font-size: 16px !important;
      float: none !important;
      display: inline-block !important;
      margin-right: 4px !important;
      margin-left: 0 !important;
    }
  }

  .pad0 {
    padding: 0 !important;
  }
  .content-block .right-side .jobsContent .jobFooter {
    position: relative;
    bottom: 0px;
    height: auto;
    width: 100%;
    margin-left: 0;
    padding: 0 10px;
    line-height: 40px;
    border: 1px solid $colorBorder;
    float: left;
    padding-top: 7px;
  }

  .cboth {
    clear: both;
  }
  .leftAlign {
    text-align: left;
  }

  .content-block .right-side .block:first-of-type {
    margin: 0px 10px 30px 0;
  }

  .MobFavoriteFilters a {
    display: inline-block;
    margin: 5px 9px 5px !important;
    padding: 0 1px 5px 1px !important;
    width: auto;
    font-size: 12px;
  }
  
}

@media (min-width: 601px) and (max-width: 767px) {

  .popup {
    width: 90% !important;
    overflow-y: hidden;
    margin: 0 !important;
    position: fixed;
    top: 100px;
    left: 5%;

    .content {
      input {
        width: 90%;
        margin: 0 5% 20px;
      }
    }

    .buttons {
      p {
        width: 100%;
        margin: 0 0 10px 10px;
      }

      a,
      a.a.buyProButton {
        float: none !important;
      }
    }
  }

  .rejectPopup,
  .deletePopup {
    width: 350px !important;
  }
  .login .buttons a {
    margin: 0px 0px 0 10px;
    float: left !important;
  }

  .register {
    .content {
      .short,
      .Select {
        width: 90% !important;
      }
    }
  }

  .newsContent {
    .lock {
      a {
        max-width: 90%;
        width: 90%;
      }
    }
  }

  .widget {
    display: none;
  }

  .content-block .right-side .newsContent .newsSingle .img {
    width: 85%;
    height: 250px;
    margin: auto;
  }

  .content-block .right-side .newsContent .newsSingle {
    margin-bottom: 20px;
  }

  section.directory .rightside .filters .firstLine a.addStartup {
    width: 22px;
    background: transparent;
    padding: 0;
    height: 25px;
    &:before {
      background: url($PUBLIC_IMG_URL+"/mobile-plus-icon.png") center center no-repeat;
      width: 21px;
      height: 21px;
      margin: 3px 0 0 0 !important;
    }
  }
  section.directory .rightside .filters .mobilefilter-main-wrap {
    padding: 0 15px;
    background: $colorWhite;
    display: table;
    width: 100%;
    border: 1px solid $colorBorder;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;

    div {
      display: block;
      background: none !important;

      &:last-of-type {
        width: 100%;
        border-right: 0;
        padding-left: 0px;
      }
    }

    .mobile-secondLine-filter-label {
      padding: 6px 0 6px 0px;
      margin: 0;
      margin-left: 0;
      font-size: 16px;
      line-height: 45px;
      display: inline-block;
      background-position: left center;
      background-repeat: no-repeat;
      display: block;
      
      a {
        background: url($PUBLIC_IMG_URL+"/icons/mobile-filters-icon.png") 95% center no-repeat;
        float: right;
        display: inline-block;
        width: 22px;
        height: 17px;
        margin-top: 12px;
      }
    }
    .mobile-secondLine-filters {
      padding-top: 15px;
      width: 100% !important;
      padding-left: 0 !important;
      display: block;

      .secondLine-options {
        display: block;
        text-align: center;
        border-right: 0px solid $colorBorder;
        width: 100% !important;
        margin-bottom: 7px;
        position: relative;

        &:last-of-type {
          width: auto !important;
          border-right: 0;
          padding-left: 0px;
        }
        a.addFilter {
          display: block;
          border-radius: 4px;
          margin: 10px 0 10px 0;
          padding: 10px 13px;
          width: 100%;
          background-color: $colorRed;
          font-size: 12px;
        }
        input {
          background: url($PUBLIC_IMG_URL+"/icons/search.png") 95% center no-repeat #f8f8f8;
          background-size: 15px;
          padding: 0 5%;
          height: 50px;
          line-height: 50px;
          color: $colorBlack1;
          border: 0;
          width: 100%;
          font-size: 12px;
          margin: 0 auto;
          margin-bottom: 2px;
        }
        select {
          width: 100%;
          margin: 0;
          padding: 0 7%;
          font-size: 12px;
          color: $colorBlack1;
          border-radius: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none;
          text-indent: 0.01px;
          appearance: none !important;
          background: url($PUBLIC_IMG_URL+"/icons/dropdown.png") 95% center no-repeat #f8f8f8;
          background-size: 12px;
        }
      }
    }
  }

  section.directory .rightside .table .row {
    display: table-row;
    width: 100%;
    padding: 0 10px;
    margin-left: -9px;
    margin-right: -9px;
    //height: 55px;
  }
  section.directory .rightside .table .row .element:first-of-type {
    max-width: 24px;
    width: 24px;
    min-width: 24px;
    padding: 0;
  }
  section.directory .rightside .table .row .element {
    display: table-cell;
    vertical-align: middle;
    padding: 10px 9px;
    max-width: 97px;
    min-width: 80px;
    border-bottom: 1px solid $colorBorder;
  }

  section.directory .rightside .table .row .element:nth-child(2) h3 {
    white-space: initial;
    max-width: 60px;
    word-break: break-all;
  }
  .lock {
    padding: 50px 15px 55px 15px;
  }

  section.directory
    .rightside
    .mobilefilter-main-wrap
    .mobile-secondLine-filters
    .secondLine-options
    input {
    background: url($PUBLIC_IMG_URL+"/icons/search.png") 2% center no-repeat #f8f8f8;
    background-size: 15px;
    padding: 0 7%;
  }

  .applicationRight {
    float: right;
    width: 72%;

    .appLink {
      width: 100%;

      h2 {
        width: 100% !important;
      }
    }
    .appDesc {
      width: 100% !important;
    }
    .info {
      width: 100% !important;

      div {
        line-height: 19px !important;
        margin-bottom: 11px !important;
      }
      span {
        font-size: 12px !important;
      }
    }
    .costs {
      width: 100% !important;
      float: left !important;
      margin: 10px 0 10px 0px !important;
      text-align: left !important;
    }
  }
  .content-block {
    .right-side {

      .jobsContent {
        .seeall {
          margin-top: 20px;
          background: none;
          color: $colorRed;
          margin-bottom: 0;
          line-height: 10px;
        }
      }
    }
  }
  .spinner {
    padding: 0;
  }
  .pad5 {
    padding: 0 5% !important;
  }

  .padbot35 {
    padding-bottom: 35px;
  }
  section.directory .rightside .table .row:first-of-type i {
    display: inline-block;
    position: relative;
    width: 13px;
    height: 13px;
  }
  section.directory .rightside .table .row:first-of-type i:before {
    font-size: 13px;
  }
  .content-block .right-side .jobsContent .jobSingle .costs a {
    border: 1px solid $colorRed;
    color: $colorRed;
    font-size: 12px;
    padding: 5px 35px;
    border-radius: 4px;
    text-transform: uppercase;
    width: auto !important;
    display: inline-block;
    text-align: center;
  }
  .addTtitle {
    .progressLine {
      margin: 68px auto 0;
      height: 543px;
      background: #e5e6e8;
      z-index: 5;
      position: absolute;
      left: 50%;
      width: 1%;
    }
    .progressBlock {
      margin-top: 30px;
      width: 100%;

      .progressRound {
        width: 100%;

        p {
          margin: 15px 0;
          padding: 5px;
          background: $colorWhite;
        }
      }
    }
  }
  .addBlock {
    .mainContent {
      .left,
      .right {
        width: 100%;
      }
    }
  }
  .addBlock .mainContent .right .row > div.col-xs-6,
  .addBlock .mainContent .right .row > div.col-sm-6,
  .addBlock .mainContent .right .row > div.col-md-6 {
    width: 100%;
  }
  .addBlock .mainContent .create {
    margin: 0px 0 14px 0;
  }

  .addBlock .mainContent .right .row > div.col-xs-3,
  .addBlock .mainContent .right .row > div.col-sm-3,
  .addBlock .mainContent .right .row > div.col-md-3 {
    width: 100%;
  }
  .addBlock .mainContent .right .addTeam {
    margin: 10px 0 10px 0;
  }
  .addBlock .mainContent .right .row,
  .addBlock .mainContent .right .row div {
    z-index: inherit;
  }
  .addBlock .mainContent .right .addAdditional .row > div {
    margin: 5px 0 !important;
  }
  .startup-progressLine {
    height: 425px !important;
  }
  .errorMessage2 {
    padding: 0px 0 0 0;
  }
  .padtop0 {
    padding-top: 0 !important;
  }
  .padbot0 {
    padding-bottom: 0 !important;
  }
  .padbot10 {
    padding: 0 0 10px 0 !important;
  }
  .addBlock .mainContent .right ul.checkboxes li {
    width: 100%;
  }

  .custom-msg {
    .successMessage {
      padding: 0px 0 0 0 !important;
    }
  }
  
  .twosteps-height .progressLine {
    height: 187px !important;
  }
  .manageJobTeamLeft {
    float: left;
    width: 95%;
  }
  .manageJobTeamRight {
    float: left;
    width: 5%;
    margin-top: 25px;
  }

  .pad10 {
    padding: 0 10px !important;
  }
  .dblock {
    display: block !important;

    .widget {
      display: block !important;
    }
  }
  .content-block .right-side .jobsContent .jobFooter {
    position: relative;
    bottom: 0;
    height: auto;
    width: 107%;
    margin-left: 0;
    padding: 0 10px;
    line-height: 40px;
    border: 1px solid $colorBorder;
    float: left;
    padding-top: 7px;
    margin-top: 20px;
    margin: 20px 0 0 0;
    left: -20px;
    right: -20px;
  }

  .progressSixSteps .progressLine {
    height: 570px;
  }
  .showOnTablet {
    display: block !important;
  }
  .zIndex9 {
    z-index: 9 !important;
  }

}

@media (min-width: 768px) and (max-width: 1200px) {
  .content-block .right-side .newsContent .newsSingle .img {
    height: auto !important;
  }
}

@media screen and (max-height: 575px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

@media (max-height: 550px) {
  .popup {
    overflow-y: hidden;
    margin-top: 95px !important;
    top: 0 !important;
  }
  .rejectPopup,
  .deletePopup {
    top: 110px !important;
  }
}

@media (max-width: 360px) {
  header {
    menu {
      margin: 0 -20px;

      ul {
        li {
          padding: 0 4px 10px 3px !important;
        }
      }
    }
  }
}

@media (min-width: 180px) and (max-width: 480px) {
  .sep-profile {
    display: none;
  }
  .rightside .filters .first-line a {
    display: inline-block;
    float: right;
    text-align: center;
    font-size: 12px;
    border-radius: 4px;
    line-height: 20px;
    margin: 10px 0 0 10px;
    padding: 0;
    width: auto;
  }
  .otherInputField {
    width: 66% !important;
  }

  .hauto {
    align-items: baseline !important;
  }

  .mh450 {
    min-height: 450px;
  }
  .progressSixSteps .progressLine {
    height: 570px;
  }
  .zIndex9 {
    z-index: 9 !important;
  }

  .cleftMobile {
    clear: left;
  }

  section.directory .rightside .filters h1 {
    font-size: 18px;
  }
  .counters-main-wrap {
    width: 100%;
    float: left;
    text-align: center;
    margin: 22px 0 0 0;
    padding: 9px 0;
    margin-left: 0;
  }
  .counters-main-wrap .col-md-4 {
    color: $colorRed;
    font-size: 15px;
    border-right: 1px solid $colorBorder;
    border: 1px solid $colorBorder;
    padding: 20px 15px;
    margin: 0 0 5% 0;
    width: 100%;
  }
  .content-block .right-side .MobJobContent {
    //padding: 20px 0 0 0  !important;
    overflow: hidden;
  }
  .content-block .right-side .MobJobContent .jobsContent {
    overflow: hidden;
  }
  .content-block .right-side .MobJobContent .jobSingle {
    padding: 10px 20px !important;
  }
  .content-block .right-side .MobJobContent .jobSingle .col-md-11 {
    text-align: left;
  }

  .addBlock .mainContent .right .row > div.userCrudWrap {
    width: 100%;
    margin: 0 0% !important;
    padding: 0;
  }

  .content-block .right-side .mainContent .services .userCrudBlock .imagePeople {
    margin: 0 2.5% 0 0 !important;
  }

}
@media (min-width: 481px) and (max-width: 767px) {
  .progressSixSteps .progressLine {
    height: 570px;
  }
}
@media (min-width: 180px) and (max-width: 359px) {
  section.directory .rightside .table .row .element:nth-child(2) .logo {
    margin: 0px 10px 0 0;
  }
  section.directory .rightside .table .row .element.name a:nth-child(2) {
    float: left;
    vertical-align: top;
  }
  section.directory .rightside .table .row .element.name a:nth-child(2) h3 {
    padding: 0;
    vertical-align: initial;
  }
  section.directory .rightside .table .row .element:first-of-type {
    vertical-align: top;
  }

  header menu ul li {
    padding: 10px 4px 0 3px !important;
  }

  section.directory .rightside .filters h1 {
    font-size: 14px;
  }
}

@media (width: 360px) {
  header menu ul li {
    padding: 10px 4px 0 3px !important;
  }
}

@media (min-width: 360px) and (max-width: 380px) {
  section.directory .rightside .table .row .element:nth-child(2) h3 {
    white-space: initial;
    max-width: 50px;
    word-break: break-all;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  header menu ul li {
    padding: 0 4px 0 3px !important;
    display: table-cell;
    width: 14%;
    text-align: center;
    line-height: 40px;
  }

  .hideOnTablet {
    display: none !important;
  }
  section.directory .rightside .filters .secondLine {
    padding: 0;
  }
  section.directory .rightside .filters .secondLine div {
    width: 20%;
    float: left;
    border-bottom: 1px solid $colorBorder;
  }
  section.directory .rightside .filters .secondLine input {
    background: none;
  }
  section.directory .rightside .filters .secondLine a.addFilter {
    margin-left: -11px;
  }

  section.directory .leftside ul li a {
    font-size: 11px;
  }

  .leftside {
    display: none;
  }

  .rightside,
  .right-side {
    width: 100%;
  }

  .tablet-width290 {
    width: 280px !important;

    input {
      background: url($PUBLIC_IMG_URL+"/icons/search.png") right center no-repeat !important;
      background-size: 15px !important;
    }
  }

  .applicationRight {
    float: right;
    width: 85%;

    .appLink {
      width: 100%;

      h2 {
        width: 100% !important;
      }
    }
    .appDesc {
      width: 100% !important;
    }
    .info {
      width: 100% !important;

      div {
        line-height: 19px !important;
        margin-bottom: 11px !important;
      }
      span {
        font-size: 12px !important;
      }
    }
    .costs {
      float: right !important;
      width: 20% !important;
      margin: 10px 0 10px 0px !important;
      text-align: left !important;
    }
    .tablet-mid-section {
      float: left;
      width: 75%;
    }
  }

  .addBlock .mainContent label {
    font-size: 11px;
  }
  .addBlock .mainContent .right .addTeam {
    margin: 10px 0 10px 0 !important;
  }

  .addTtitle .progressBlock .progressRound {
    width: 138px;
  }
  .progressSixSteps .progressBlock .progressRound {
    width: 16%;
    margin: 0 !important;
  }
  .content-block .right-side .jobsContent .jobFooter {
    position: relative;
    bottom: 0px;
    height: auto;
    width: 100%;
    margin-left: 0;
    padding: 0 10px;
    line-height: 40px;
    border: 1px solid $colorBorder;
    float: left;
    padding-top: 7px;
  }

  .content-block .right-side .jobsContent .jobFooter {
    position: relative;
    bottom: 22px;
    height: auto;
    width: 107%;
    margin-left: 0;
    padding: 0 10px;
    line-height: 40px;
    border: 1px solid $colorBorder;
    float: left;
    padding-top: 7px;
    margin-top: 20px;
    margin: 20px 0 0 0;
    left: -20px;
    right: -20px;
  }

  .showOnTablet {
    display: block !important;
  }

  .zIndex9 {
    z-index: 9 !important;
  }
  .btnReportMenu {
    margin: 0 0 10px 0;
  }

  .counters-main-wrap {
    width: 100%;
    float: left;
    text-align: center;
    margin: 22px 0;
    padding: 9px 9px;
    margin-left: 0;
  }
  .counters-main-wrap .col-md-4 {
    color: $colorRed;
    font-size: 15px;
    border-right: 1px solid $colorBorder;
    border: 1px solid $colorBorder;
    padding: 20px 15px;
    margin: 0 1% 0 0;
    width: 32.6%;
    float: left;
  }
}

@media screen and (max-height: 575px) {
}

@media (width: 1024px) {
  .tablet-col-left {
    float: left;
    width: 70%;
  }

  .tablet-col-right {
    float: left;
    width: 30%;

    .hidden {
      display: block !important;
    }
  }
  .hideOnTabletLandscape {
    display: none !important;
  }

  .applicationRight {
    float: right;
    width: 85%;

    .appLink {
      width: 100%;

      h2 {
        width: 100% !important;
      }
    }
    .appDesc {
      width: 100% !important;
    }
    .info {
      width: 100% !important;

      div {
        line-height: 19px !important;
        margin-bottom: 11px !important;
      }
      span {
        font-size: 12px !important;
      }
    }
    .costs {
      float: right !important;
      width: 20% !important;
      margin: 10px 0 10px 0px !important;
      text-align: left !important;
    }
    .tablet-mid-section {
      float: left;
      width: 75%;
    }
  }
  .content-block .right-side .team div,
  .content-block .right-side .investors div,
  .content-block .right-side .mainContent .services div {
    min-height: 85px;
  }
  section.directory .leftside .profile .image + h2 {
    font-size: 14px;
  }
  section.directory .leftside .profile .profileLink {
    font-size: 12px;
  }

  .addBlock .mainContent label {
    font-size: 11px;
  }

  .content-block .right-side .jobsContent .jobFooter {
    position: relative;
    bottom: 0;
    height: auto;
    width: 107%;
    margin-left: 0;
    padding: 0 10px;
    line-height: 40px;
    border: 1px solid $colorBorder;
    float: left;
    padding-top: 7px;
    margin-top: 20px;
    margin: 20px 0 0 0;
    left: -20px;
    right: -20px;
  }

  .btnReportMenu {
    margin: 0 0 10px 0;
  }

  .counters-main-wrap {
    width: 100%;
    float: left;
    text-align: center;
    margin: 22px 0;
    padding: 9px 9px;
    margin-left: 0;
  }
}

@media (min-width: 180px) and (max-width: 320px) {
  .btnReportMenu {
    margin: 0 0 10px 10px !important;
    width: 120px !important;

    &:first-child {
      margin-left: 0 !important;
    }
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .btnReportMenu {
    margin: 0 10px 10px 0;

    &:first-child {
      margin-left: 0 !important;
    }
  }
}

/**************** Begin Styles by Aso ***************/
//shared in all pages
.list {
  div {
    li {
      &:hover {
        color: $colorRed;
      }
    }
  }
}
// Application.js
.dateFont {
  font-size: 12px;
}
.containerFilterResult {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.rowFilterResult {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.imgFilterResult {
  height: 100;
  width: 100;
  margin-right: 50;
}

.initialLogo {
  width: 45px;
  height: 45px;
  border-radius: 4px;
  border: 1px solid $colorBorder;
  display: flex;
  align-items: center;
  justify-content: center;
}

.favCol {
  width: 3%;
}
.nameCol {
  width: 20%;
  padding-left: 10px;
}
.industryCol {
  width: 12%;
  padding-right: 5px;
}
.totalFundCol {
  width: 10%;
  //padding-left: 10px;
}
.followCol {
  width: 8%;
  // padding-left: 10px;
}
.msgCol {
  width: 10%;
  padding-right: 10px;
}
.numCol {
  width: 10%;
}
.nameOthersCol {
  width: 20%;
  padding-left: 10px;
}
.peopleNameCol {
  width: 30%;
  padding-left: 10px;
}

.peopleJobCol {
  width: 15%;
}

.peopleRoleCol {
  width: 15%;
}

.fundNameCol {
  width: 25%;
  padding-left: 10px;
}

.fundStageCol {
  width: 15%;
}

.fundAmountCol {
  width: 15%;
}
.radio {
  clear: both;

  input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;

    & + label:before {
      content: " ";
      display: block;
      float: left;
      width: 18px;
      height: 18px;
      margin: 0 10px 0 5px;
      background-size: contain !important;
      border-radius: 9px;
      border: 4px solid $colorWhite;
      padding-bottom: 10px;
    }

    &:checked {
      & + label:before {
        background-color: $colorBlack;
      }
    }

    &:not(checked) {
      & + label:before {
        background-color: $colorWhite;
      }
    }
  }

  label {
    padding-left: 0 !important;
  }
}

.popup .buttons a {
  line-height: 30px;
  font-size: 12px;
}

.imgWrapper {
  width: 30%;
}
.imgPad {
  padding-right: 20px;
}

.form-group {
  margin-bottom: 25px !important;
}
//
.mcPopupHelptext {
  background: #222 none repeat scroll 0% 0%;
  color: $colorWhite !important;
  border: 0px solid $colorWhite;
  border-radius: 4px;
  font-size: 12px !important;
  line-height: 1.5;
  font-family: "helvetica neue", arial, helvetica, freesans, "liberation sans", "numbus sans l", sans-serif !important;
  max-width: 200px;
  font-weight: 400;
  padding: 10px;
  white-space: normal;
  position: absolute;
  min-width: 200px;
  top: -8px;
  right: -210px;
  z-index: 20;
  display: none;
  text-align: left !important;
  text-transform: none !important;

  &:before {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #222 transparent transparent;
    left: -10px;
    top: 9px;
    content: "";
    font-size: 0px;
    position: absolute;
  }
}

.subscription {
  width: 45%;
  display: inline-block;
}

// PaymentSuccess.js
.payment-success-wrapper {
  //margin: auto;
  box-shadow: 2px 2px 4px #d9d9d9;
  background-color: $colorWhite;
  padding: 15px;
  height: 500px;
  padding-top: 150px;
}
.payment-success {
  padding: 20px;
  text-align: center;

  a {
    color: $colorRed;
  }
}

// SingleUser.js
.textInformation-row {
  display: flex;
  flex-direction: row;
  margin: 25px 0px;
}
.textInformation-row-title {
  width: 25%;
  font-size: 16px;
  font-weight: 600;
}
.textInformation-row-content {
  width: 75%;
}

@media (max-width: 600px) {
  .dateFont {
    font-size: 13px;
  }
  .containerFilterResult {
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
  .rowFilterResult {
    width: 400px;
  }
  .imgFilterResult {
    margin-right: 10%;
  }
  .applyBtnFilterResult {
    width: 73%;
  }

  .stickyBtn {
    //width: 60%;
  }

  .initialLogo {
    width: 38px;
    height: 38px;
  }
  .favCol {
    width: 7%;
    text-align: center;
  }
  .nameCol {
    width: 30%;
    padding-left: 5px;
  }
  .industryCol {
    width: 25%;
  }
  .totalFundCol {
    width: 14%;
  }
  .followCol {
    width: 14%;
  }
  .msgCol {
    width: 17%;
    padding-right: 5px;
  }
  .numCol {
    width: 20%;
  }
  .nameOthersCol {
    width: 38%;
    padding-left: 5px;
  }
  .peopleNameCol {
    width: 50%;
  }
  .peopleJobCol {
    width: 25%;
  }
  .peopleRoleCol {
    width: 25%;
  }
  .fundNameCol {
    width: 50%;
    padding-left: 5px;
  }
  .fundStageCol {
    width: 25%;
  }
  .fundAmountCol {
    width: 25%;
  }
  .radio {
    input {
      & + label:before {
        width: 16px;
        height: 16px;
      }
    }
  }
  .popup .breadCrumbs {
    padding-left: 10px;
  }
  .popup .breadCrumbs a {
    font-size: 17px;
  }
  .filterBlock .content .left a {
    font-size: 13px;
    margin: 0px 0px;
    padding: 0px 5px;

    &:after {
      width: 10px;
      height: 12px;
      //margin-top: 10px;
    }
  }
  .filterBlock .content .left {
    width: 50%;
    height: 64vh;
  }
  .popup .title h4 {
    font-size: 13px !important;
    margin-top: 0px;
  }
  .filterBlock .content .right {
    width: 50%;
    height: 64vh;
  }
  .filterBlock .content .right .checkbox {
    margin-bottom: 15px;
    margin-left: 0px;

    & + label:before {
      width: 18px;
    }
  }
  .filterBlock .content .right .radio {
    margin-bottom: 15px;
    margin-left: 0px;

    & + label:before {
      width: 18px;
    }
  }
  .filterBlock .content .right label {
    font-size: 13px;
  }
  .filterBlock .content .right button {
    line-height: 30px;
    font-size: 12px;
  }
  .popup .content input {
    font-size: 14px;
    line-height: 30px;
  }

  .imgWrapper {
    width: 100%;
  }
  .imgPad {
    padding-right: 0px;
  }

  .logoImage {
    width: 130px;
  }

  .subscription {
    width: 100%;
  }

  .jobDashBoardBlock {
    .title {
      a {
        margin: 0 0;
      }
    }
  }
  // SingleUser.js
  .textInformation-row-title {
    width: 100%;
  }
  .textInformation-row-content {
    width: 100%;
  }

}

/**************** End Styles by Aso ***************/

/**************** reseach page mobile fix ***************/

@media (max-width: 768px) {
  .jobDashBoardBlock {
    .title {
      a {
        margin: 0 0;
      }
    }
  }
}

@media (max-width: 1024px) {
  .noContact {
    min-height: 150px;
  }

  .fundingTableCell {
    padding: 5px 15px;
  }
}

@media (max-width: 769px) {
  .connectionCardContent {
    .infoBlock {
      .userDetails {
        flex-direction: column;
        p {
          margin-right: 0;
          margin-top: 0 !important;
          span {
            margin-right: 0;
          }
        }
      }
    }

    .buttons {
      margin-right: 10px;
      width: auto;
    }
  }
}

@media (max-width: 768px) {
  .connectionContainer {
    .verticalMenu {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $colorBorder;
      a {
        border-bottom: none;
      }
    }
  }
  .noContact {
    p {
      font-size: 15px;
    }
  }
}

/*********** ***********/
@media (max-width: 768px) {
  .spin {
    //margin: auto;
    //position: absolute;
    //height: 100%;
    //z-index: 999999;
    .spinnerLogo {
      display: block;
      margin: auto;
      //width: 14%;
      width: 54px;
      //transform: scale(.70);
    }
    .spinnerDot {
      width: 9px;
      height: 9px;
      margin-top: 12px;
    }

    .spinnerDot2,
    .spinnerDot3 {
      margin-left: 13.5px;
    }
  }
}

/*@media (min-width: 768px) {*/
.newsIndividual {
  padding: 0px 60px;
}

.news-article-tag {
  background: lightgray;
  padding: 1px 10px;
  border-radius: 50px;
  font-size: 14px;
  margin-right: 7px;
  margin-top: 5px;
  display: inline-block;

  a {
    color: $colorBlack !important;
  }
}

@media (min-width: 768px) {
  .responseRate {
    margin-top: 40px;
    text-align: center;
    padding-left: 50px;
  }
}

@media (max-width: 768px) {
  .responseRate {
    margin-top: 50px;
    text-align: center;
  }
}

.sm-date-range-picker {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .popup {
    width: 90% !important;
    max-height: 80vh;
    margin: 0 !important;
    position: fixed;
    top: 130px;
    left: 5%;
    .redButton{
      margin: 0 !important;
    }
  }

  .comp-other-checkbox-wrap {
    margin-top: -25px;
    padding-top: 0px !important;
  }
}

/*} */

/* Dana End */
