$bottom: -50px;
$overviewResponsiveBreakpoint:  900px;
$portfolioResponsiveBreakpoint: 700px;

.profile-section-box {
    background: $colorWhite;
    padding: 30px !important;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.08) 0px 8px 24px;

    &.no-padding {
        padding: 0 !important;
        box-shadow: none !important;
    }

    &.basic-info-card {
        padding: 25px;

        @media (max-width: 1200px) {
            &:first-child {
                margin-top: 30px;
            }
        }
    }
}

.sice-page {
    margin-bottom: $bottom !important;

    .sice-block {
        border-radius: 8px !important;
    }

    .widget-title {
        border-bottom: 1px solid $colorBorder;
        padding: 14px;

        h3 {
            font-size: 16px;
        }

        i {
            color: $colorRed;
            margin-right: 6px;
        }
    }

    .video-box {
        margin: 24px 0;
        max-width: 500px;
        overflow: hidden;
    }

    .mid-width-info-box {
        h2 {
            font-size: 18px;
            margin-bottom: 20px;
            color: $colorBlack;
        }

        .info-box-title {
            font-size: 16.5px;
        }
    }

    .description-text,
    .description-text p {
        line-height: 25px;
        font-size: 15px;
    }

    .description-text {
        p+p {
            margin-top: 20px;
        }
    }

    .profile-section-heading {
        font-weight: 700 !important;
        font-size: 18px !important;
        color: $darkBlue1;
        margin-bottom: 15px !important;
    }

    .profile-section-secondary-heading {
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        color: $darkBlue1;
        margin-bottom: 15px;
    }

    .scrollmargin {
        scroll-margin-top: 225px !important;
    }

    .profile-nav-bar {
        background: $colorWhite;
        padding: 12px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        justify-content: center;
        position: sticky;
        z-index: 10;
        margin-bottom: 30px;

        &.sticky {
            background-color: $colorWhite;
            width: 100%;
            padding: 10px;
            position: fixed;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            transition: transform 0.2s ease-in-out;
            left: 0;
            border-radius: unset;
            z-index: 1000;
        }

        ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            align-items: center;
				overflow: auto;
            // mobile bugfix
            // prevent chrome from copying the word and suggesting a google search
            li,
            li a {
                user-select: none;
            }

            li {
                a {
                    color: #222;
                    margin: 0px, 24px, 0px, 24px;
                    text-decoration: none;
                    padding: 10px 20px;
                    display: inline-block;
                    border-radius: 6px;
                    text-align: center;
                    scroll-margin-right: 10px; // manage scroll offset

                    &:hover {
                        color: $colorRed;
                    }
                }

                .active {
                    background-color: $colorRed;
                    color: $colorWhite;

                    &:hover {
                        color: $colorWhite;
                    }
                }
            }
        }
    }

    // setting scroll offset for nav scrollIntoView
    .profile-section-box,
    .company-overview,
    #section-investments,
    #section-portfolioexits,
    #section-managedfunds,
    #section-investmentsinstartups,
    #section-pendingfunding,
    #section-funding,
    #section-startupinvestments,
    #section-investmentsinfunds,
    #section-fundingrounds,
	 #section-startupacquisitions
	 {
        scroll-margin-top: 95px;

        @media (max-width: 768px) {
            scroll-margin-top: 90px;
        }
    }

    // manage section margins for the whole page
    .right-side > div,
    .leftside > div:not(:first-child),
    #section-investments .funding-box:not(:first-child) {
        margin-top: 30px !important;

        @media (max-width: 600px) {
            margin-top: 20px;
        }
    }

    // hide empty divs
    .right-side,
    .leftside {
        & > div:empty {
            display: none;
        }
    }

    .portfolio-insights {
        h3 {
            font-size: 15.5px;
            font-weight: 500;
            color: #101828;
        }

        .portfolio-insights-box {
            padding: 0;

            @media (max-width: 575px) {
                &:not(:first-child) {
                    margin-top: 20px;
                }
            }
        }
		  .d3-chart-legend .legend-item .legend-text{
				width:80% ;
		  }
    }

    .basic-info-card {
			.news-card-image {
				margin-top: 25px;
			}

        .basic-info-card-item {
				.news-image{
					height: 100%;
					min-height: 190px;
					background-size: cover !important;
					background-repeat: no-repeat !important;
					border: 0.75px $colorBorder solid;
					border-radius: 5px;
					background-position: center;
					border-radius: 8px !important;
					margin-bottom: 10px;
				}

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            .heading {
                font-weight: 500;
                color: #9098A5;
                margin-bottom: 2px;
            }

            .heading,
            .subheading {
                font-size: 15px;
            }

            .subheading {
                display: flex;
                font-weight: 700;
                flex-wrap: wrap;
                color: #344054;
                align-items: center;

                &.icon-header {
                    font-size: 18px;
                }
                .text {
                    p {
                        font-size: unset;
                    }
                }

                .pill-badge {
                    margin: 5px 7px 5px 0;
                    border-radius: 16px !important;
                }

                a {
                    white-space: initial;
                    color: $colorRed;
                    padding-bottom: 0 !important;
                    overflow-wrap: anywhere;
                    display: flex;
                    align-items: flex-start;
                    font-weight: 400;

                    i {
                        margin-top: 4px;
                    }
                }

                .fa-lock {
                    margin-right: 6px;
                }

                .social-tab {
                    margin-top: -4px;

                    i {
                        color: $colorWhite;
                        background-color: $colorRed;
                        border: none;
                        width: 32px; height: 32px;
                    }
                }
            }

        }

			@media (max-width: 1200px) {
				.news-container {
					display: grid;
					grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
					grid-gap: 20px;

					a {
						max-width: 100%;
					}

					.news-card-image {
						margin-top: 0;
					}
				}
			}

			@media (max-width: 612px) {
				.news-image{
					min-height: 230px !important;
				}
			}
    }

    @media (max-width: 768px) {
        .profile-nav-bar {
            margin-bottom: 30px;
            width: 100%;
            overflow-x: auto;
            background: transparent;
            padding: 0px;
            justify-content: flex-start;

            &.sticky {
                display: flex;
                background-color: $colorWhite;
                padding: 10px;
                top: 0;
                position: fixed;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                transition: transform 0.2s ease-in-out;
            }

            ul {
                display: flex;
                list-style: none;
                margin: 0;
                padding: 0;
                flex-grow: 1;
                align-items: center;
					 overflow: visible;

                li {
                    margin: 0px 5px;

                    a {
                        display: inline-block;
                        width: max-content;
                        text-align: center;
                        text-decoration: none;
                        background-color: $colorWhite;
                        color: #222;
                        margin: 3px, 24px, 3px, 24px;
                        padding: 10px 20px;
                        display: inline-block;
                        border-radius: 6px;

                        &:hover {
                            color: $colorRed;
                        }
                    }

                    .active {
                        background-color: $colorRed;
                        color: $colorWhite;

                        &:hover {
                            color: $colorWhite;
                        }
                    }
                }
            }
        }
    }

    .company-overview {
        transition: transform 0.2s ease-in-out;
        display: flex;
        justify-content: space-between;

        .general-overview {
            overflow: hidden;

            h5 {
                font-weight: 700;
                margin-bottom: 10px;
            }
        }

        .showmore {
            display: block;
            text-align: start !important;

            a {
                font-weight: 600;
                color: $colorBlack;
                text-decoration: none !important;
                color: $colorRed;

                img {
                    max-width: 12px;
                }

                &:hover {
                    color: $colorRed;
                }
            }
        }

        .fact-sheet {
            background-color: #F8F8F8;
            border-radius: 8px;
            padding: 20px;
            min-width: 317px;
            max-width: 317px;
            height: fit-content;

            @media (min-width: calc($overviewResponsiveBreakpoint + 1px)) {
                margin-left: 15px;
            }

            @media (max-width: $overviewResponsiveBreakpoint) {
                margin-top: 15px;
            }

            @media (max-width: 600px) {
                width: 100%;
                max-width: unset;
                min-width: unset;
            }

            .section {
                margin-bottom: 18px;
            }

            .section-heading {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                color: $darkBlue1;
                margin-bottom: 10px;

                i {
                    min-width: 17px;
                    margin-right: 4px;
                }
            }

            ul {
                margin-left: 14px;
                list-style-type: disc !important;

                li {
                    list-style: disc !important;

                    &:not(:last-child) {
                        margin-bottom: 2px;
                    }

                    span {
                        font-weight: 600;
                        color: $darkBlue1;
                    }
                }
            }

            .section-row {
                display: flex;
                justify-content: space-between;

                .investor-overview {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    flex: 0 0 auto;
                    margin-right: 8px;
                    max-width: 33%;

                    .heading {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #9098A5;
                        margin-bottom: 4px;
                    }

                    .content {
                        font-size: 17px;
                        font-weight: 700;
                        line-height: 25px;
                        letter-spacing: -0.02em;
                        color: $darkBlue1;
                    }
                }
            }

            .blur {
                filter: blur(5px);
                pointer-events: none;
            }
        }
    }

    @media (max-width: $overviewResponsiveBreakpoint) {
        .company-overview {
            flex-direction: column !important;
        }

        .general-overview {
            width: 100% !important;
        }
    }

    .portfolio-list-section {

        .card-header,
        .carousel-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            color: $darkBlue1;
            background-color: transparent;
            border-bottom: none;
            padding: 0;
            margin-bottom: 18px;
        }

        .portfolio-card {

            &:not(:last-child) {
                margin-bottom: 28px;
            }

            .card-container {
                margin: 0 auto;

                .card-header {
                    @media (max-width: $portfolioResponsiveBreakpoint) {
                        display: block;
                    }

                    .portfolio-facts,
                    .show-more-button {
                        font-size: 15px;
                    }

                    .portfolio-facts {
                        font-weight: 500;
                        color: #667085;

                        .investor-name,
                        .portfolio-count,
                        .popular-industry {
                            font-weight: 600;
                            color: $darkBlue1;
                        }
                    }

                    .show-more-button {
                        background-color: transparent;
                        color: $colorRed;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;
                        padding: 0;

                        &:hover {
                            color: $colorRed;
                        }
                    }
                }

                .card-details,.managed-portfolio-card-details {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
                    gap: 25px;

                    @media (min-width: 699px) and (max-width: 1200px) {
                        grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
                    }

                    .card-content {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        border-radius: 8px;
                        padding: 22px;
                        border: 1px solid $colorBorder;
                        transition: transform 0.2s ease-in-out;
                        overflow: hidden;

                        // element spacing
                        .startupLogo,
                        .card-heading,
                        .card-pill,
                        .card-description {
                            &:not(:last-child) {
                                margin-bottom: 14px;
                            }
                        }

                        .card-pill .pill-badge {
                            cursor: auto;
                            white-space: pre-wrap;
                        }

                        .card-pill.card-pill-multiple {
                            display: flex;
                            flex-wrap: wrap;

                            .pill-badge-tooltip:not(:last-child) {
                                margin-right: 10px;
                                margin-bottom: 10px;
                            }
                        }

                        .startupLogo {
                            width: 50px;
                            height: 50px;
                            border-radius: 8px;
                            margin-right: 16px;

                            h2 {
                                font-size: 25px;
                            }
                        }

                        .card-heading {
                            font-size: 15px;
                            font-weight: 600;
                            color:#101928;
                            line-height: 18px;
                        }

                        .card-description {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 19px;
                            text-align: left;
                            color: #667185;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }

                        .card-secondary-info {
                            margin-bottom: 8px;
                        }

                        .card-link {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 18px;
                            text-align: left;
                            color: $colorRed;
                            overflow-wrap: anywhere;
                            display: flex;
                            align-items: flex-start;

                            i {
                                margin-top: 3px;
                                margin-right: 4px;
                            }
                        }
                    }
                }

					 .managed-portfolio-card-details {
						grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

						@media(max-width: 1500px) {
						 grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
						  }

						 @media(max-width: 1295px) {
							 grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
						 }

						 @media(max-width: 1200px) {
							 grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
						 }

						 @media(max-width: 968px) {
							 grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
						 }
					 }
            }

            // mobile view: a full-width scrollable section
            @media (max-width: $portfolioResponsiveBreakpoint) {
                .card-details {
                    display: flex !important;
                    overflow-x: auto;
                    gap: unset !important;

                    .card-content {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        border-radius: 8px;
                        padding: 16px;
                        border: 1px solid #ccc;
                        width: 300px;
                        max-width: 300px;
                        min-width: 300px;
                        margin-bottom: 10px;

                        &:not(:last-child) {
                            margin-right: 20px;
                        }

                        .card-logo {
                            width: 40px;
                            height: 40px;
                            border-radius: 8px;
                            margin-right: 16px;
                        }

                        .card-heading {
                            font-size: 16px;
                            margin-bottom: 8px;
                        }

                        .card-description {
                            font-size: 12px;
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }

        .relations-carousel {
            .carousel-container {
                margin: 0 auto;

                .carousel-header {
                    .show-more-button {
                        padding: 8px 0px 8px 16px;
                        background-color: transparent;
                        color: #101828;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;

                        &:hover {
                            color: $colorRed;
                        }
                    }
                }

                .carousel {
                    width: 100%;
                    overflow-x: auto;
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    scroll-snap-type: x mandatory;

                    .carousel-slide {
                        flex: 1 0 30%;
                        aspect-ratio: 1;
                        flex-flow: column nowrap;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 120px;
                        max-width: 120px;
                        max-height: 120px;
                        border-radius: 8px;
                        background-color: $colorWhite;
                    }

                    @media (max-width: 600px) {
                        .carousel-slide {
                            flex: 1 0 90%;
                        }
                    }
                }
            }
        }

		.managed-funds-pagination {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 20px;

			@media (min-width: $fundingTableBreakpoint) {
				border-top: 1px solid #eee;

				&:not([data-table-type="portfolioExits"]) {
					margin-top: 24px;
				}
			}

			.btn {
				border-radius: 8px;
				box-shadow: 0px 1px 2px 0px #1018280D;
				border: 1px solid #D0D5DD;
				padding: 8px 12px;

				// text
				font-size: 14px;
				color: $darkBlue1;
				text-align: center;

				@media (min-width: 401px) {
					min-width: 105px; // keep both buttons same width

					&:first-child i {
						margin-right: 8px
					}

					&:last-child i {
						margin-left: 8px;
					}
				}

				// smaller screens
				// hide 'previous' and 'next' label in button
				@media (max-width: 400px) {
					span {
						display: none;
					}
				}
			}
		}
    }

    .fund-portfolio-section {
        .tabbed-display {
            margin-bottom: 12px;
            max-width: 100%;
            white-space: nowrap;
            overflow-x: auto;

            a {
                font-size: 15.5px;
            }

            .btn {
                margin-bottom: 10px;
            }
        }

        .profile-section-secondary-heading {
            margin-top: 40px;
        }
    }

    .team-members-section {
        .section-heading {
            display: flex;
            justify-content: space-between;
            color: #101828;
            background-color: transparent;
            border-bottom: none;
            margin-bottom: 8px;

            @media (max-width: 600px) {
                display: block;
            }

            .count-text {
                color: #667085;
                font-size: 15px;

                @media (max-width: 600px) {
                    margin-bottom: 15px;
                    margin-top: -5px;
                    display: block;
                }

                .count-span-text {
                    font-weight: 600;
                    color: $darkBlue1;
                }
            }
        }

        .card-container {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            gap: 20px;

            @media (min-width: 599px) and (max-width: 1200px) {
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            }

            .card-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 8px;
                padding: 18px;
                border: 1px solid #EFEFEF;
                justify-content: space-between;

                .card-details-flex {
                    display: flex;

                    .member-profile-pic {
                        width: 58px;
                        height: 58px;
                        min-width: 58px;
                        max-width: 58px;
                        min-height: 58px;
                        max-height: 58px;
                        border-radius: 50%;
                        border: 1px solid $colorBorder;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;

                        h2 {
                            font-size: 27px;
                        }

                        .image {
                            height: 100%;
                            width: 100%;
                            background-size: cover;
                            border-radius: 50%;
                        }
                    }

                    .member-role {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        margin-top: 2px;
                    }
                }

                h5 {
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 20px;
                    color: $darkBlue1;
                    text-transform: capitalize;
                }

                .social-tab {
                    justify-content: unset !important;
                    margin-top: 8px !important;

                    a {
                        i {
                            border: none;
                            background-color: $colorRed;
                            color: $colorWhite;
                            width: 28px;
                            height: 28px;
                        }
                    }
                }

                .team-section-contact {
                    a {
                        margin-top: 14px;

                        &.team-viewed-email {
                            display: inline-block;
                            color: $darkBlue1;
                            min-height: 31px; // match button padding for alignment

                            i {
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 600px) {
            .card-container {
                width: 100%;
                overflow-x: auto;
                display: flex;
                gap: 20px;
                align-items: center;
                scroll-snap-type: x mandatory;

                .card-content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    border-radius: 8px;
                    padding: 18px;
              
                    min-height: 160px;
                    min-width: 250px;
              
                    border: 1px solid #EFEFEF;
                    justify-content: space-between;
                }
            }
        }
    }

    .paywall-content {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .paywall-text-blur {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.question-section {
    .mid-width-info-box{
        box-shadow: none !important;

        .info-box-container {
            padding: 20px 0px !important;

            &:last-child {
                padding-bottom: 0 !important;
            }
        }
    }

    // expanded
    // hide header checkmarks
    &[aria-expanded="true"] {
        td:not(:first-of-type) {
            display: none;
        }

        div.d-flex::before {
            content: "\f078";
        }
    }

    // collapsed
    // add bottom border
    &[aria-expanded="false"] {
        td:not(:first-of-type) {
            border-bottom: 0.75px solid $colorTableBorder;
        }

        div.d-flex::before {
            content: "\f054";
        }
    }
}

.paywall-box {
    .question-section {
        .mid-width-info-box{
            // .info-box-container {
            box-shadow: none !important;

            .info-box-container,
            .info-box-title,
            .info-box-body {
                margin: 0;
            }
        }

        .answers{
            filter: blur(5px);
            pointer-events: none;
        }
    }

	 .lock-section-curtain{
			margin: 0 !important;
			padding: 0 !important;
			pointer-events: none;
         user-select:none;
	 }
}

// scrollbar

.sice-page .portfolio-card .card-details,
.sice-page .carousel,
.sice-page .profile-nav-bar,
.sice-page .tabbed-display {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar:hover {
        height: 8px;
    }

    &::-webkit-scrollbar {
        z-index: 999999;
        opacity: 0.1;
    }

    &::-webkit-scrollbar:hover {
        opacity: 1;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        z-index: 999999;
        background: $scrollbar_track;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $scrollbar_thumb;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $scrollbar_thumb_hover;
    }
}