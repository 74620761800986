/* === pricing === */

// responsive margin
$margin: 4vw;
$marginx2: 8vw;

.pricing,
.payment {

    .billing-toggle {
        margin-top: 40px;

        @media (max-width: 600px) {
            margin-left: -30px;
            margin-right: -30px;
            margin-bottom: 30px;
        }

        .switch {
            position: relative;
            margin: 0 auto;
            color: #6c757d;

            input {
                position: absolute;
                top: 0;
                z-index: 2;
                opacity: 0;
                cursor: pointer;
                height: 3rem;
                width: 50px;
                margin: 0;
                // visibility: hidden;
            }

            input:checked {
                z-index: 1;
            }

            input:checked+label {
                opacity: 1;
                cursor: default;
            }

            input[id^=toggle-monthly] {
                margin-left: 110px;
            }

            input[id^=toggle-annual] {
                margin-left: 50px;
            }

            label {
                opacity: 0.7;
                transition: opacity 0.25s ease;
                cursor: pointer;
                font-size: 15.5px;
                line-height: 32px;
                display: inline-block;
                height: 100%;
                margin: 0;
                text-align: center;
                padding: 0px 10px;
                width: auto;
                color: #343A40;
            }

            .toggle-outside {
                border-radius: 2rem;
                padding: 0.25rem;
                overflow: hidden;
                height: 2rem;
                background: $colorWhite;
                position: absolute;
                width: 6rem;
                box-shadow: 0px 8px 20px 0px rgba(23, 29, 48, 0.2);
            }

            .toggle-inside {
                border-radius: 5rem;
                background: $colorRed;
                position: absolute;
                transition: 0.25s ease all;
                height: 1.5rem;
                width: 2.5rem;
                right: 5px;
            }

            label:last-of-type {
                margin-left: 6rem;
            }

            input:checked~.toggle-outside .toggle-inside {
                left: 0.25rem;
            }

            input~.toggle-outside .toggle-inside {
                left: 3.25rem;
            }
        }
    }

    .billing-toggle.centered .switch {
        label {
            line-height: 21px;
            vertical-align: top;
            margin-top: 4px;
        }

        .text-muted {
            display: block;
            font-size: 14px;
            color: $colorRed !important;
        }
    }
}

.pricing {
    .page-header:first-of-type {
        @media (max-width: 600px) {
            padding: 0px 20px;
            margin-top: 10px;
        }
    }

    .btn {
        font-size: 15px;
        color: $colorWhite;
        letter-spacing: 0.5px;
        box-shadow: none;
    }

    .pricing-details {
        margin-top: 6px;

        * {
            font-weight: 400;
            font-size: 16px;
            color: #ccc;
        }

        .sub-text {
            font-size: 13px;
        }
    }

    .demo-box {
        max-width: unset !important;
    }

}

/* === payment page === */

.payment {
    .billing-toggle {
        margin-top: 35px;
        width: 350px;

        @media (max-width: 600px) {
            /* stop toggle from overlaying circle */
            margin-top: 30px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
            width: 100%;
        }

        .switch {
            /* absolute position so the circle doesn't break the toggle's position on mobile */
            position: absolute;

            label {
                margin-top: -18px;
                line-height: 1.5;
                font-size: 14px;
                letter-spacing: 0.05rem;
            }

            input[id^=toggle-monthly] {
                margin-left: 100px;
            }

            label[for^="toggle-monthly"] {
                padding-left: 0;
            }

            label[for^="toggle-annual"] {
                padding-right: 0;
            }
        }
    }

    a {
        color: $colorRed;
        cursor: pointer;
    }

    .callout {
        padding: 7px 10px;
        margin-top: 0px;
        border: 1px solid $colorBorder;
        border-left-width: 0.25rem;
        border-radius: 0.25rem;
        display: block;
        letter-spacing: 0.01rem;
        width: auto;
        margin-top: 30px;

        @media (min-width: 600px) {
            max-width: 350px;
        }

        a,
        span {
            color: $colorRed;
            text-decoration: underline !important;
            cursor: pointer;
        }
    }

    .callout.border-red {
        border-left-color: $colorRed;
    }

    .callout.border-black {
        border-left-color: $colorBlack;
        margin-bottom: 20px;
    }

    .section-header {
        margin-bottom: 5px;
    }

    h5.section-subhead {
        font-size: 16px;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    .shadow-box {
        background: $colorWhite;
        box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.2);
        padding: 30px;
    }

    .order-details {
        /* take same height as the card beside it */
        // display: flex;

        input {
            display: block;
            margin-bottom: 0 !important;
            margin-top: 8px;
            width: 150px;
        }

        .terms-agree {
            margin-top: 20px;
            font-size: 13px;
        }

        .btn {
            margin-top: 20px;
        }

        .order-list {
            padding-top: 20px;

            .order-flex {
                display: flex;
                justify-content: space-between;
            }

            .text-muted {
                font-size: 14px;
            }
        }

        .promo-box {
            display: unset;

            a,
            input {
                padding: 5px 10px !important;
                font-size: 13px !important;
            }

            input {
                display: inline;
                /* keep */
                width: 125px !important;
            }

            .red {
                margin-top: 10px;
            }
        }

        .order-list.order-total {
            border-top: 1.5px dashed rgba(0, 0, 0, 0.1) !important;
            border-bottom: 1.5px dashed rgba(0, 0, 0, 0.1);
            font-weight: 600;
            padding-bottom: 20px !important;
        }

        .order-list:not(:first-of-type) {
            border-top: 1px solid #cccccc4d;
        }

        .order-list:not(:last-of-type) {
            padding-bottom: 25px;
        }
    }

    .purchase-type-checkbox {
        margin-bottom: 30px;

        ul, p, label {
            font-size: 15.5px;
        }

        ul {
            margin-left: 15px;
            margin-bottom: 20px;
            margin-top: 15px;

            li {
                list-style: disc;
                margin-bottom: 2px;
            }
        }

        .custom-checkbox {
            border: 1px solid $colorBorder;
            border-radius: 5px;
            padding: 5px;
            padding-left: 32px;
            margin-top: 15px;
            transition: 0.5s all;

            &[data-selected="true"] {
                border: 1px solid $colorRed;
            }

            @media (min-width: 600px) {
                max-width: 350px;
            }

            .custom-control-label {
                font-size: 14px;

                &::before {
                    border-radius: 50%;
                }
            }
        }

        hr {
            margin-top: 30px;
        }
    }
}

.order-details,
.form-box-layout {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    @media (max-width: 991px) {
        padding-bottom: 20px;
    }

    .error {
        border: 1px solid #D22248a8 !important;
    }

    .react-select-error.__control {
        border: 1px solid #D22248a8;
    }

    label {
        width: 100%;
        font-weight: normal;
        margin-bottom: 10px;
        font-size: 14px !important;
        line-height: 22px;
    }

    .custom-control-label {
        margin-bottom: 0 !important;
    }

    label:not(:first-of-type) {
        padding-top: 13px;
    }

    input,
    select:not(#pagination-limit-select),
    textarea,
    label ~ .margin-reset,
    label ~ [class$="-container"] {
        width: 100%;
        border-radius: 4px;
        font-size: 14px !important;
        margin-bottom: 25px !important;
        line-height: 23px;
        color: $colorBlack;
    }

    input,
    select:not(#pagination-limit-select),
    textarea {
        border: 1px solid $colorBorder !important;
    }

    input {
        padding: 7px 10px !important;
    }

    select:not(#pagination-limit-select) {
        padding: 10px 5px;
    }

    select:disabled {
        opacity: 1;
    }
}

.form-box-layout {

    .shadow-box {
        padding: 0;
        overflow: hidden;
    }

    .plan-details {
        padding: 30px;

        .focus {
            font-size: 14px;
        }
    }

    .form-box {
        .card-logos {
            margin-top: 15px;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 30px;

            img {
                height: 16px;
                margin-right: 15px;
            }
        }
    }

    .circle {
        height: 150px;
        width: 280px;
        border-radius: 0 0 200px 200px;
        color: $colorWhite;
        float: right;
        margin-right: -100px;
        margin-top: -40px;
        background: $colorBlue1;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 600px) {
            width: 260px;
            margin-top: -50px;
        }

        .circle-text[data-trial="true"] {
            @media (min-width: 600px) {
                margin-top: 72px;
            }
        }

        .circle-text {
            color: #ccc;
            font-size: 20px;
            margin-left: -200px;
            margin-top: 80px;
            height: 100%;
            width: 100%;
            padding-right: 20px;
            padding-top: 15px;

            @media (max-width: 600px) {
                padding-right: 13px;
            }

            .pricing-details {
                text-align: right;

                .sub-text {
                    font-size: 15px;
                    line-height: 20px;
                }
            }
        }
    }
}

/* === plan colors for circles === */

.payment .circle {
    // smooth color transition
    transition: 0.5s all;
}

.payment .circle[data-plan="Business"] {
    background: $colorBlue3;
}

.payment .circle[data-plan="Report"]  {
    background: $colorBlue4;
}

/* === img box element === */

.demo-box {
    h4 {
        margin-bottom: 10px;
        font-size: 22.5px;
        letter-spacing: 0.175px;
        line-height: 28px;
        font-weight: 700;
    }
}

.demo-box.img-left,
.demo-box.img-right {
    background: $colorWhite;
    box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.2);
    padding: 40px;
    margin: 50px auto;
    max-width: 1100px; // stop element getting too long when sidenav's minimized

    @media(max-width: 1250px) {
        margin: 25px;
    }

    .btn {
        font-size: 17px;
    }

    .demo-export {

        @media (min-width: 576px) and (max-width: 767px) {
            padding-left: 50px;
        }

        @media (max-width: 575px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        p {
            font-size: 16px;
            padding-right: 10px;

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }

            @media (min-width: 600px) and (max-width: 900px) {
                margin-bottom: 15px;
            }
        }

        .report-text,
        .report-list {
            margin-top: 20px;
            margin-bottom: 20px;

            @media (min-width: 600px) and (max-width: 900px) {
                margin-bottom: 10px;
                margin-top: 10px;
            }

            @media (max-width: 767px) {

                // hide second list to make content shorter on mobile
                &+.report-list:nth-child(1) {
                    display: none;
                }
            }

            @media (max-width: 576px) {
                width: auto !important;
            }
        }

        .report-demo-btns {
            @media (max-width: 576px) {
                justify-content: center;
                display: flex;
            }

            .btn {
                padding: 12px 10px;
                width: 100%;
                max-width: 260px;

                @media (min-width: 500px) and (max-width: 900px) {
                    font-size: 14px;
                    padding: 10px;
                    margin-top: 10px;
                }

                i {
                    margin-right: 10px;
                    font-size: 16px;
                }
            }

            .btn-main-outline {
                @media (max-width: 767px) {
                    margin-top: 20px;
                }

                i {
                    font-size: 19px;
                }
            }

        }
    }

    .demo-graphic {
        justify-content: center;
        display: flex;
        align-items: center;
        margin: -5px;

        @media (min-width: 600px) and (max-width: 767px) {
            padding-left: 25px;
            padding-right: 0;
        }

        img {
            height: 100%;
            max-height: 260px;

            @media (min-width: 767px) and (max-width: 1000px) {
                padding-right: 10px;
            }

            @media (max-width: 767px) {
                max-height: none;
                height: 180px;
                margin-bottom: 30px;
            }
        }
    }
}

.demo-box.img-right {

    @media (max-width: 1100px) {
        padding: 40px !important;

        .demo-export {
            padding: 0 !important;
        }

        .demo-graphic {
            padding-right: 0;
        }
    }

    .demo-graphic {
        justify-content: flex-end;

        img {
            padding: 0 !important;
        }

        @media (max-width: 574px) {
            justify-content: center;
        }
    }

    .demo-export {
        @media (max-width: 767px) and (min-width: 576px) {
            padding-left: 0;
        }

        .report-text {
            @media (max-width: 574px) {
                text-align: center;
            }
        }

        .report-demo-btns {
            .btn {
                padding: 12px;

                @media (max-width: 574px) {
                    width: 250px !important;
                }
            }
        }
    }

    .report-list {
        @media (max-width: 767px) {
            // hide second list to make content shorter on mobile
            display: none;
        }
    }

}

/* === style edits on report page === */

.payment[data-page-type="getReport"] {
    .form-box {
        .section-header {
            font-size: 17.5px;
            margin-bottom: 20px !important;
        }
    }
}

/* === pricing, payment shared styles */

.features-list {
    p {
        // indent so list with two lines doesn't wrap under checkmark
        text-indent: -25px;
        margin-left: 25px;
    }

    p::before {
        margin-right: 10px;
        font-size: 15px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
    }
}

.report-list {
    p {
        margin: 0;
        margin-bottom: 10px;
        font-size: 15.5px;
        // indent so list with two lines doesn't wrap under checkmark
        text-indent: -25px;
        margin-left: 25px;
    }

    p::before {
        margin-right: 10px;
        font-size: 15px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        color: $colorRed;
    }
}

/* === payment page > base plan info === */

.pricing-info-box {
    margin-top: 20px;
    padding: 0;

    .features-list {
        margin-bottom: 15px !important;

        p {
            margin-bottom: 3px;
        }
    }

    hr {
        margin-top: 25px;
        margin-bottom: 30px;
    }

    // classname starting with
    [class^=pricing-info-box-link] {
        font-size: 15px;
        display: flex;
        align-items: center;

        i {
            color: $colorRed;
            font-size: 20px;
            margin-right: 8px;
        }

        a {
            margin-left: 1px;
        }
    }
}