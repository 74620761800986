$PUBLIC_IMG_URL:"https://magnitt-public-dev.s3.eu-west-2.amazonaws.com/public/images";
// styles from libraries
@import url('../../node_modules/rc-slider/dist/rc-slider.css');
@import url('../../node_modules/react-datepicker/dist/react-datepicker.css');
@import url('../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css');
@import url('../../node_modules/react-date-range/dist/styles.css');
@import url('../../node_modules/react-date-range/dist/theme/default.css');
@import url('../../node_modules/react-toastify/dist/ReactToastify.css');
@import url('../../node_modules/simplebar/dist/simplebar.min.css');
@import url('../../node_modules/slick-carousel/slick/slick.css');
@import url('../../node_modules/slick-carousel/slick/slick-theme.css');

// variables (must be imported before styles)
@import './variable';

// global styles
@import '/src/components/app.scss';
@import '/src_temp/styles/theme.scss';
@import '/src_temp/styles/mobile.scss';

// page styles
@import '/src/components/home/mainPage.scss';
@import '/src/pages/contactSales/styles/contact-page.scss';
@import '/src/pages/tableauDashboards/styles/advanced-analytics.scss';
@import '/src_temp/components/Pages/ContactUs.scss';
@import '/src_temp/components/Pages/Marketing.scss';
@import '/src_temp/components/Pages/Privacy.scss';
@import '/src_temp/components/Pages/analyst-hours-usage-policy.scss';
@import '/src_temp/styles/directories.scss';
@import '/src_temp/styles/pages/careers.scss';
@import '/src_temp/styles/pages/custom-market-maps.scss';
@import '/src_temp/styles/pages/about-us.scss';
@import '/src_temp/styles/pages/sice-page.scss';
@import '/src_temp/styles/pages/pricing.scss';
@import '/src_temp/styles/pages/payment.scss';

// component styles
@import '/src_temp/components/User/Users.scss';
@import '/src/components/dataExport/styles/dataExport.scss';
@import '/src_temp/styles/tables.scss';
@import '/src_temp/styles/components/company-type-modal.scss';
@import '/src_temp/styles/components/funding-table.scss';
@import '/src_temp/styles/components/simple-detail-section.scss';
@import '/src_temp/styles/components/startup-benchmarking.scss';
@import '/src_temp/styles/components/directories/dir-sliding-content.scss';
@import '/src_temp/styles/components/directories/funding-row.scss';
@import '/src_temp/styles/components/directories/directory-charts.scss';
@import '/src_temp/styles/components/directories/pdf-ppt-export.scss';
@import '/src_temp/styles/components/entity-logo.scss';
@import '/src_temp/styles/components/profile-header.scss';



/* overwriting defaults*/

html,
body {
    text-align: start;
    overflow-x: hidden;
    position: relative;
}

body {
    overflow: auto !important;
}

p {
    font-size: 14px;
}

a {
    text-decoration: none !important;
    color: $colorBlack;
}

*:focus {
    outline: none !important;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.bg-dark {
    background-color: $colorWhite !important;
}

.bg-light {
    background-color: $colorWhite !important;
}

.react-datepicker-wrapper {
    //background-color: $colorWhite;
    width: 100%;
    border-radius: 5px;
    font-size: 13px;

    .react-datepicker__input-container {
        width: 100%;

        input {
            border: 1px solid $colorBorder;
            //padding: 7px 10px;
            padding: 5px 15px 5px 15px;
            width: 100%;
            border-radius: 5px;
        }
    }
}

.react-datepicker-popper {
    z-index: 1000;
}

.error {
    border: 1px solid $colorRed !important;

    // for ReactSelect component
    &[class$=-container] {
        border-radius: 4px;
    }
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

// container with min height to push footer down, for pages with short content
.min-height-box {
    min-height: 350px;
}
