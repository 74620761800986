@use "sass:math";

// page breakpoint
$pricing_breakpoint_mobile: 700px;
$pricing_breakpoint_tablet: 1100px;

// section breakpoint
$pricing_breakpoint_client: 800px;

.pricing-page {

    .page-header {

        h1,
        h2 {
            font-weight: 700;
            color: $colorPureBlack;
        }

        .sub-header {
            text-transform: uppercase;
            font-size: 13px;
            margin-bottom: 15px;
            margin-top: 0 !important;
            color: $colorOrange;
            font-weight: 600;
        }
    }

    .section-plans-wrapper {
        background-color: $colorWhite;
        position: relative;
        overflow: hidden; // hide overflow of lines-graphic
    }

    .pricing-plans {
        padding: 50px;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;

        // for lines graphic
        z-index: 2;
        position: relative;

        @media (max-width: 600px) {
            padding: 60px 40px;
        }

        .page-header {
            margin-top: 0;
            max-width: 725px;
            margin: 0 auto;
            margin-bottom: 45px;

            h2 {

                span {
                    color: $colorRed;
                }
            }

            p {
                max-width: 600px;
                margin: 0 auto;
            }
        }

        $pricingPlanBoxMargin: 18px;
        $sectionsCount: 3;
        $totalMargins: calc($pricingPlanBoxMargin * (2 * $sectionsCount));

        .pricing-plan-box {
            background: $colorWhite;
            box-shadow: 0 40px 40px -20px rgba(23, 29, 48, 0.1);
            width: calc((100% - $totalMargins) / $sectionsCount);
            border-radius: 20px;
            border: 1px solid #EFF0F6;
            margin: $pricingPlanBoxMargin;

            @media (min-width: 1300px) {
                margin-top: 0;
                margin-bottom: 0;
            }

            @media (min-width: $pricing_breakpoint_tablet) and (max-width: 1200px) {
                width: 30%;
            }

            @media (max-width: $pricing_breakpoint_tablet) {
                width: 43%;
                max-width: 350px;
            }

            @media (max-width: $pricing_breakpoint_mobile) {
                width: 100%;
                margin-right: 0 !important;
                margin-left: 0 !important;
                max-width: 350px;

                &:first-child {
                    margin-top: 0 !important;
                }

                &:last-child {
                    margin-bottom: 0 !important;
                }
            }

            .btn-block+.btn-block {
                margin-top: 15px;
            }

            .circle-box {
                position: relative;

                .circle {
                    height: 100px;
                    width: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0 0 200px 200px;
                    color: $colorWhite;
                    display: flex;
                    align-items: center;
                    right: -56px;
                    top: -11px;
                    position: absolute;

                    .circle-text {
                        color: $colorWhite;
                        font-size: 14px;
                        margin-top: -20px;
                        margin-left: -31px;

                        .sub-text {
                            font-size: 14px;
                        }
                    }

                    .btn {
                        border: 1px solid $colorWhite;
                        position: absolute;
                        top: 342px;
                        margin-left: -62px;
                    }

                    * {
                        font-size: 17px;
                        text-align: right;
                    }
                }
            }

            .plan-details {
                padding: 35px;
                height: 100%;
                position: relative;

                .plan-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                }

                h5 {
                    font-weight: 700;
                    color: $colorPureBlack;
                    font-size: 23px;
                }

                .focus {
                    font-size: 10px;
                }

                .section-header {
                    margin-bottom: 15px;
                }

                .btn {
                    display: block;
                    padding: 10px 12px;
                    white-space: nowrap;
                    margin: 20px 0;
                }

                i {
                    margin-right: 5px;
                }

                p {
                    margin-bottom: 5px;

                    &.plan-desc,
                    &.plan-features {
                        font-size: 15px;
                    }

                    &.plan-desc {
                        margin-bottom: 15px !important;
                    }

                    &.plan-features {
                        color: $colorPureBlack;
                        font-weight: 700;
                    }
                }

                ul.features-list {
                    min-height: 130px; // align 'view more' btn

                    li {
                        font-size: 14.5px;
                        margin-bottom: 5px;
                        text-indent: -25px;
                        margin-left: 25px;

                        i {
                            font-size: 17px;
                        }
                    }

                    li::before {
                        font-family: "Font Awesome 5 Free";
                        font-weight: 600;
                        margin-right: 8px;
                        font-size: 16px;
                    }

                    li[data-has-access="true"] {
                        color: $colorPureBlack;

                        &::before {
                            content: "\f058";
                            color: $colorOrange;
                        }
                    }

                    li[data-has-access="false"]::before {
                        content: "\f111";
                    }

                    a.helptext {
                        text-indent: initial;

                        i {
                            color: $colorBlack;
                            font-size: 15px;
                        }

                        .message {
                            margin-left: 10px;
                        }
                    }
                }

                .view-more {
                    margin-top: 15px;
                    text-align: center;
                    font-size: 15px;
                    color: $colorPureBlack;
                    font-weight: 600;
                    cursor: pointer;

                    i {
                        margin-left: 5px;
                    }

                    &[data-expanded="true"] i {
                        transform: rotate(180deg);
                    }
                }

                .far {
                    color: $colorRed;
                }

                .client-details {
                    text-align: center;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;

                    .logo-box {
                        color: $colorWhite;
                        margin-top: 5px;

                        img {
                            max-width: 100%;
                            height: auto;
                            padding: 0px 20px;
                            padding-bottom: 10px;

                            @media (max-width: 1000px) {
                                max-height: 85px;
                                margin-top: -10px;
                            }
                        }
                    }
                }
            }
        }

        // vat terms
        // :after pseudo element of the last plan (enterprise)
        .pricing-plan-box:nth-of-type(4):after {
            color: #6c757d;
            content: "* The prices above don't include VAT";
            font-size: 13px;
            position: absolute;
            margin-top: 10px;

            @media (max-width: 600px) {
                width: 100%;
                right: 15px;
                text-align: right;
            }
        }

        & + .lines-graphic {
            // bg
            background-image: url($PUBLIC_IMG_URL+"/vectors/lines.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left bottom;
            opacity: 0.9;

            // position
            position: absolute;
            width: 100%;
            height: 550px;
            bottom: -90px;
            left: -190px;

            @media (min-width: 1500px) {
                left: 70px;
            }

            @media (max-width: 1100px) {
                display: none;
            }
        }

    }

    $stats-img-width: 65%;
    $stats-txt-width: math.div((100% - $stats-img-width), 2);
    $stats-txt-offset: 35%;

    $stats-breakpoint: 1200;
    $stats-breakpoint-end: $stats-breakpoint + 1;

    .section-features-wrapper {
        background-color: $colorRed;
        overflow: hidden; // hide .section-features :after overflow

        .page-header {
            margin-top: 0 !important;
            max-width: 800px;
            padding: 60px 20px 40px 20px;
            margin: 0 auto;

            h2 {
                line-height: 1.25;
                color: $colorWhite;
            }
        }
    }

    .section-features {
        padding: 75px 40px;
        padding-top: 0 !important;
        position: relative;
        justify-content: center;

        @media (max-width: ($stats-breakpoint + 'px')) {
            padding: 65px 40px;
        }

        &>div:nth-child(1),
        &>div:nth-child(3) {

            .feature-box {
                border: 1px solid $colorWhite;
                box-shadow: 0px 2px 12px 0px #DADADB14;
                border-radius: 14px;
                padding: 25px;
                transition: background-color 0.3s ease-in;

                @media (max-width: ($stats-breakpoint + 'px')) {
                    max-width: 425px;
                    margin-left: auto;
                    margin-right: auto;
                }

                &[data-animation="true"] {
                    background-color: $colorWhite;

                    h6 {
                        color: $colorPureBlack;
                    }
                }

                &[data-animation="false"] {
                    h6, p {
                        color: $colorWhite;
                    }
                }

                h6, p {
                    transition: color 0.3s ease-in;
                }

                h6 {
                    font-size: 19px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14.5px;
                }
            }
        }

        // for desktop
        @media (min-width: ($stats-breakpoint-end + 'px')) {

            display: flex;
            padding-bottom: 0;
            align-items: center;
            max-width: 1490px;
            margin: 0 auto;

            // white section to cut off bg
            &:after {
                content: "";
                height: 80px;
                width: 125%;
                position: absolute;
                background: $colorWhite;
                bottom: 0;
                left: -12.5%;
                z-index: 100;

                @media(min-width: 1200px) and (max-width: 1350px) {
                    height: 50px;
                }
            }

            &>div {
                z-index: 200;
            }

            &>div:nth-child(1),
            &>div:nth-child(3) {
                width: $stats-txt-width;
                margin-top: -17.5%; // offset for align-items: center; of .section-features

                .feature-box {
                    width: calc(100% + $stats-txt-offset);

                    &:first-child {
                        margin-top: 60px;
                    }

                    &:last-child {
                        margin-top: 45px;
                    }
                }
            }

            &>div:nth-child(2) {
                position: relative;
                width: $stats-img-width;
                padding: 0px 25px
            }

            &>div:nth-child(3) .feature-box {
                margin-left: -$stats-txt-offset;
            }

        }

        // for mobile + tab
        @media (max-width: ($stats-breakpoint + 'px')) {
            &>div {
                text-align: center;

                img {
                    margin-top: 50px;
                    margin-bottom: 40px;
                }

                &:nth-child(1) p:first-child {
                    margin-top: 0;
                }

                .feature-box:last-child {
                    margin-top: 40px;
                }

                // tablet only
                &:nth-child(1),
                &:nth-child(3) {
                    @media (min-width: 800px) and (max-width: ($stats-breakpoint + 'px')) {
                        display: flex;
                        align-items: center;

                        .feature-box {
                            margin-top: 0;
                            max-width: 45%;
                        }
                    }
                }
            }
        }
    }

    .section-counts {
        padding: 60px 40px;
        background: $colorWhite;

        @media (min-width: ($stats-breakpoint + 'px')) {
            margin-top: -40px; // offset .section-features
        }

        @media (max-width: 600px) {
            padding: 40px 30px;
        }

        p {
            font-size: 27px;
            font-weight: 600;
            text-align: center;
            color: $colorBlue3;
            max-width: 900px;
            margin: 0 auto;
        }

        .row {
            align-items: center;
            max-width: 1200px;
            margin: 0 auto;
            margin-top: 15px;
            justify-content: center;

            @media (max-width: 575px) {
                max-width: 360px;
                margin-top: 10px;
            }

            [class^="col-"] {
                margin: 20px 0px;
                margin-bottom: 0 !important;
                text-align: center;

                @media (max-width: ($stats-breakpoint + 'px')) {
                    &:not(:first-child) {
                        margin: 25px 0px;
                    }
                }
            }

            .feature-icon-box {
                display: flex;
                align-items: center;

                i {
                    height: 54px;
                    width: 54px;
                    border-radius: 16px;
                    background-color: $colorRed;
                    font-size: 27px;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    margin-right: 16px;
                    color: $colorWhite;
                    flex-shrink: 0;
                }

                span {
                    font-size: 15.5px;
                    text-align: left;

                    b {
                        color: $colorRed;
                    }
                }
            }
        }
    }

    .pricing-contact-form {
        padding: 65px 40px;
        background-color: #E1EBEC;

        // account for header overlay height when scrolling to this section in logged out view
        &[data-loggedin="false"] {
            scroll-margin-top: 125px;
        }

        .db-box.no-background {
            padding-right: 0 !important;
            padding-left: 0 !important;
            padding-top: 0 !important;
            margin-top: 0;
        }

        &>.row {
            max-width: 1250px;
            margin: 0 auto;

            .pricing-form-header {
                text-align: left;
                color: #2C373B;
                width: 100%;
                font-size: 17px;
                font-weight: normal;

                span {
                    font-weight: bold;
                    font-size: 24px;
                    display: block;
                    line-height: 32px;
                }
            }

            // vertical align
            .text-section {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 100%;
            }

            .page-header {
                margin: 0;

                @media (min-width: 991px) {
                    text-align: left;
                }

                @media (max-width: 991px) {
                    margin: 0 auto;
                    max-width: 660px;
                }

                &,
                p {
                    color: $colorWhite;
                }

                h1 {
                    font-size: 38px;
                    font-weight: 700;
                }

                p {
                    font-size: 22px;
                    line-height: 30px;
                    margin-top: 30px;

                    &:last-child {
                        font-weight: 700;
                    }

                    @media (max-width: 600px) {
                        font-size: 20px;
                    }
                }

            }
        }

        .contact-form-box {
            padding: 0;

            h6 {
                color: $colorPureBlack;
            }

            .db-box {
                background-color: $colorWhite;
                margin: 0 auto;
                box-shadow: 0 0 6px 0 rgb(0 0 0 / 6%);
                border-radius: 12px;
                padding: 40px !important;

                .react-tel-input input {
                    height: 37px;
                }

                .btn {
                    padding: 12px;
                }

                h6 {
                    text-align: left;
                }
            }

            .contact-form {
                max-width: unset;
            }
        }
    }

    .pricing-clients {
        margin-bottom: 0 !important;

        p {
            color: #898989;
        }

        .row {
            align-items: center;
            max-width: 1000px;
            margin: 0 auto;
            justify-content: center;
            margin-bottom: 0 !important;

            [class^="col-"] {
                margin: 35px 0px;
                margin-bottom: 0 !important;
                text-align: center;

                @media (max-width: $pricing_breakpoint_client) {
                    margin: 25px 0px;
                }
            }

            .img-container {
                padding: 20px;
                border-radius: 12px;
                margin-bottom: 0;
                background-color: rgba(255, 255, 255, 0.4); // #FFF 40% transparent

                // flex
                display: flex;
                align-items: center;
                justify-content: center;

                // larger screens only
                @media (min-width: 1200px) {
                    height: 125px;
                }

                // smaller screens only
                @media (min-width: 500px) and (max-width: 1199px) {
                    img {
                        padding: 5px;
                    }
                }

                @media (max-width: 1199px) {
                    height: 85px;
                }
            }

            img {
                // filter: sepia(10%) brightness(70%);
                max-height: 62px;
                max-width: 130px;
                object-fit: contain;
                width: 100%;

                @media (max-width: $pricing_breakpoint_client) {
                    max-height: 45px;
                    max-width: 110px;
                }
            }
        }
    }
}