.marketing-page {

    .contact-form-box {
        .contact-form {
            input {
                margin-bottom: 12px;
            }
        }
    }

    .pricing-focus {
        margin-top: 30px;
    }

    .social-tab {
        margin-top: 2px;
        margin-left: 10px;

        i {
            color: #343a40;
            width: 28px;
            height: 28px;

            &.fa-instagram {
                font-weight: 600;
            }
        }
    }

}

.stats-main,
.stats-sub {
    .stats-header {
        display: flex;
        align-items: center;
    }
}

.stats-main {
    .stats-header {
        color: $colorRed;
        font-size: 40px;
        line-height: 30px;
        margin-bottom: 5px;

        &.stats-header-md {
            font-size: 26px;
            margin-bottom: 0px;
            color: $colorBlack;

            span {
                font-weight: 400;
            }
        }

        i {
            margin-right: 10px;
        }

        span {
            font-weight: 600;
        }
    }

    .stats-line {
        color: $colorRed;
        font-size: 15px;
    }
}

.stats-sub {
    .stats-header {
        color: #343a40;
        font-size: 16.5px;
        margin-bottom: 10px;

        i {
            margin-right: 8px;
            font-size: 17px;
        }
    }

    .stats-count {
        font-weight: 600;
        color: $colorBlue1;
        margin-right: 8px;
        font-size: 26px;

        &+span {
            font-size: 14px;
        }
    }

    .stats-logo {
        width: 100%;
        height: 60px;
        border: 1px solid $colorBorder;
        border-radius: 5px;
        padding: 10px;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 60%;
            max-height: 90%;
        }
    }

    a.stats-logo {
        &:hover {
            border: 1px solid $colorRed;
            transition: 0.5s all;
        }
    }
}

.row.stats-limit-width {
    max-width: 900px;
    margin: auto;
    justify-content: center;
}

.stats-responsive-box {
    margin: 0 30px;
    margin-top: 25px;
    min-width: 100px;
    max-width: 300px;

    @media (max-width: 600px) {
        margin: 0 25px;
        margin-top: 25px;
    }
}

.stats-general .stats-responsive-box {
    max-width: 180px;

    i {
        font-size: 29px;
        margin-bottom: 10px;
    }

    @media (max-width: 700px) {
        width: 140px;
    }
}

/* marketing element on newsletter page */
/* .marketing-focus: an alternate version of demo-box element that's full width with gray bg
  not currently used but we might use it elsewhere in future */

.newsletter-page {
    .marketing-focus {
        background: $colorWhite1;

        .demo-box {
            background: inherit;
        }
    }

    .testimonial-section {
        background: $colorBlack1;

        @media (min-width: 1000px) {
            background: url($PUBLIC_IMG_URL + "/bg/icons-bg.png"), $colorBlack1;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        h4 {
            color: $colorWhite;
        }

        h4 {
            text-align: center;
        }

        [class^="col"] {
            margin-top: 30px;

            // prevent from being too wide on tablets
            max-width: 400px;

            &>div {
                padding: 20px;
                border-radius: 5px;
                height: 100%;

                // flex
                align-items: center;
                display: flex;
                justify-content: center;

                p {
                    font-size: 16px;

                    span:last-child {
                        text-indent: 15px;
                        display: inline-block;
                    }

                    .quote-symbol {
                        font-size: 32px;
                        font-family: Georgia, serif;
                        line-height: 14px;
                        margin-top: 10px;
                        position: absolute;
                    }
                }
            }
        }
    }

    .marketing-focus,
    .testimonial-section,
    .past-newsletters {
        .demo-box {
            max-width: 1300px;
            margin: 0 auto;
            padding: 60px 40px;
            box-shadow: unset;

            h4 {
                font-size: 25px;
            }
        }
    }

    .past-newsletters {
        background: $colorWhite1;

        .demo-box {
            background: inherit;
        }

        h4 {
            text-align: center;
        }

        .newsletter-list {
            max-width: 1000px;
            margin: 0 auto;

            &>div {
                margin-top: 30px;

                a {
                    font-weight: bold;
                    font-size: 16.5px;
                    display: block;

                    @media (min-width: 800px) {
                        max-width: 450px;
                    }
                }

                span {
                    font-size: 15px;
                    display: block;
                    margin-top: 5px;
                }
            }
        }
    }
}